import * as React from "react"
import reactStringReplace from 'react-string-replace';
import "./section_no_bg.module.scss"

function SectionNoBG(props) {
  let containerClasses;
  let narrowContainer;

  if(props.withImage === "left"){
    containerClasses = "row justify-content-end p-0 px-xxl-0"
  }
  else if (props.withImage === "right") {
    containerClasses = "row justify-content-start p-0 px-xxl-0"
  } else {
    containerClasses = "row justify-content-center p-0 px-xxl-0"
  }

  if (props.narrow) {
    narrowContainer = "col-12 col-sm-10 col-md-8"
  } else {
    narrowContainer = "col-12"
  }

  return (
    <section
      id={props.id}
      className={
        props.withImage
          ? `text-section with-image-${props.withImage} ${props.classes}`
          : `text-section ${props.classes}`
      }
    >
      <div className="container-xxl text-section-container text-white">
        <div
          className={
            props.autoHeight
              ? "text-section-content d-flex flex-column auto-height"
              : "text-section-content d-flex flex-column"
          }
        >
          {props.image && (
            <div className="image-slider">
              <img src={props.image} alt={props.alt ? `${props.alt}` : ``} />
            </div>
          )}
          <div className="container-xxl flex-grow-1 d-flex flex-column justify-content-center px-3">
            <div
              className={containerClasses}
            >
              <div
                className={
                  props.withImage
                    ? `text-section-text-container col-12 col-md-6 mt-4 mt-md-0`
                    : `text-section-text-container ${narrowContainer}`
                }
              >
                {props.sectionHeadline && (
                  <h5 className="mb-3">{props.sectionHeadline}</h5>
                )}
                {props.headline && <h1 className="mb-4">{props.headline}</h1>}
                {props.content ? (
                  <div
                    className="lead mt-3"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                ) : (
                  <p className="lead mt-3">
                    {reactStringReplace(props.text, /(\n)/g, (match, i) => (
                      <br key={`space-${i}`}/>
                    ))}
                  </p>
                )}
              </div>
            </div>
            {!props.image && (
              <div className={containerClasses}>
                <div
                className={ `text-section-text-container ${narrowContainer}`
                }
                >
                {props.children}
                </div>
              </div>
            )}
             {props.image && (
            <div>
              {props.children}
            </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionNoBG
